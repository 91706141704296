import { Column, ContrastBox, Row } from '@mattilsynet/mt-ui'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useDeepEffect,
  useMidlertidigTilsynsobjekt,
  useTypedSelector,
} from '../../../common/custom-hooks'
import {
  offlineInSakAndTilsynsobjekt,
  offlineInTypeTilsynSelect,
  useSingleToast,
} from '../../../common/toast'
import GenerellInfomasjonCard from '../../../components/generell-informasjon-card'
import { KontrollpunkterSection } from '../../../features/kontrollpunkter'
import { PageLoading } from '../../../components/page-loading'
import SakCardInfo from '../../../components/sak-card-info'
import SakSearch from '../../../components/sak-search'
import TilsynsobjekterCardInfo from '../../../components/tilsynsobjekter-card-info'
import TypeTilsyn from '../../../components/type-tilsyn'
import { kvitteringActions } from '../../../ducks/kvittering/actions'
import { SAVING } from '../../../ducks/kvittering/types'
import { clearSak, getSak } from '../../../ducks/sak/actions'
import { useKvittering } from '../../../features/kvitteringer'
import { onKeyDown } from '../../../helpers'
import { IStoreState } from '../../../reducers/types'
import { useViewsContext } from '..'
import { useKvitteringId } from '../../../common/kvittering-context'
import { VirksomhetModal } from '../../../modals'
import { initialSearch } from '../../forbered-new-tilsyn/utils'
import { AktivitetModal } from '../../../modals/aktivitet-modal'
import { kodeverk } from '@mattilsynet/mt-common'
import { useBildeMetadataList } from '../../../features/bilder'

interface ISakOgTilsynsobjektSlideProps {
  blockContinueFromZero: boolean
}

export const SakOgTilsynsobjektSlide = ({
  blockContinueFromZero,
}: ISakOgTilsynsobjektSlideProps) => {
  const dispatch = useDispatch()
  const singleToast = useSingleToast()
  const { viewDispatch } = useViewsContext()

  const currentKvittering = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.data
  )
  const sak = useSelector((state: IStoreState) => state.sak)

  const kvitteringId = useKvitteringId()
  const {
    data: kvittering,
    isLoading,
    isFetching,
    refetch,
  } = useKvittering(kvitteringId)

  // Prepopulate cache with initial data.
  // Using this since `initialData` is considered fresh data
  useBildeMetadataList(kvitteringId)

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const {
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakHjemmel,
    sakTilgang,
  } = useTypedSelector((state: IStoreState) => state.sak)

  const isLoadingSak = useSelector((state: IStoreState) => state.sak.loading)

  const [sakNumberValue, setSakNumberValue] = useState(() =>
    currentKvittering?.noarksakAar
      ? `${currentKvittering.noarksakAar}/${currentKvittering.noarksakSekvensnummer}`
      : sakNumber
  )

  const [showSak, setShowSak] = useState(false)

  // Slett isFetchingRef og refetch-useEffecten når vi er ferdige
  // med å konvertere til tanstack
  const isFetchingRef = useRef(isFetching)
  useEffect(() => {
    isFetchingRef.current = isFetching
  }, [isFetching])

  useDeepEffect(() => {
    if (!isFetchingRef.current && currentKvittering.tilsynsobjekter) {
      refetch()
    }
  }, [currentKvittering.tilsynsobjekter, refetch])

  const openTidligereKontrollpunkter = useCallback(
    () => viewDispatch('tidligereKontrollpunkter'),
    [viewDispatch]
  )

  const onChange = (e) => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setSakNumberValue(onKeyDown(e, sakNumberValue))
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        sakNumberValue: onKeyDown(e, sakNumberValue),
      })
    )
  }

  const onAddSak = useCallback(() => {
    if (sakNumberValue.length > 0) {
      setShowSak(true)
      if (isOffline) {
        return singleToast.showToast(offlineInSakAndTilsynsobjekt())
      }

      dispatch(getSak(sakNumberValue))
      dispatch(kvitteringActions.setStatus(SAVING))
    }
  }, [sakNumberValue, dispatch, singleToast, isOffline])

  const onCancelSak = () => {
    setShowSak(false)
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }

    dispatch(clearSak())
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        noarksakAar: '',
        noarksakSekvensnummer: '',
        sakNumberValue: '',
      })
    )
    setSakNumberValue('')
  }

  const onChangeTypeTilsyn = (typeValue) => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect())
    }

    dispatch(kvitteringActions.setTypeTilsyn(typeValue))
    dispatch(kvitteringActions.setStatus(SAVING))
  }

  const [isVirksomhetModalOpen, setIsVirksomhetModalOpen] = useState(false)

  const onCancelVirksomhetModal = () => {
    setIsVirksomhetModalOpen(false)
  }

  const onAddRemoveTilsynsobjekt = useCallback(() => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setIsVirksomhetModalOpen(true)
  }, [isOffline, singleToast, setIsVirksomhetModalOpen])

  const kodeverkAktiviteterBehandlesIMats = kodeverk.hooks.useKodeverk(
    'AKTIVITET',
    { filter: 'H' }
  )
  const {
    isAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    setSelectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt,
  } = useMidlertidigTilsynsobjekt(
    kodeverkAktiviteterBehandlesIMats,
    setIsVirksomhetModalOpen
  )

  useEffect(() => {
    if (currentKvittering.id) {
      const midlertidigTilsynsobjekt = currentKvittering.tilsynsobjekter?.find(
        (t) => t.midlertidig
      )

      if (midlertidigTilsynsobjekt) {
        setSelectedAktivitet({
          label: midlertidigTilsynsobjekt.aktivitetsBeskrivelse,
          value: midlertidigTilsynsobjekt.aktivitetsId,
        })
      }
    }
  }, [
    currentKvittering.id,
    currentKvittering.tilsynsobjekter,
    setSelectedAktivitet,
  ])

  if (!currentKvittering) {
    return <PageLoading loadingText="Laster inn tilsynskvittering..." />
  }

  const hasKvitteringSaksnummer = Boolean(
    currentKvittering.noarksakSekvensnummer && currentKvittering.noarksakAar
  )
  const hasError =
    sakNumberValue.length > 0 &&
    blockContinueFromZero &&
    !hasKvitteringSaksnummer
  const sakSearchErrorText = !hasError
    ? ''
    : 'Saken må legges til eller tekstfeltet må være tomt'

  return (
    <Column spacing={3} fluid key={1}>
      <Row className="title">
        <h1>Sak, tilsynstype, tilsynsobjekt og kontrollpunkter</h1>
      </Row>
      <Row>
        <Column spacing={2} fluid>
          <TypeTilsyn
            onChange={onChangeTypeTilsyn}
            currentType={currentKvittering.typeTilsyn}
            currentTypeTypeTilsynBeskrivelse={
              currentKvittering.typeTilsynBeskrivelse
            }
          />
          <ContrastBox noBorders backgroundColor="white">
            <SakSearch
              onAddSak={onAddSak}
              onChange={onChange}
              sakNumberValue={sakNumberValue}
              errorText={sakSearchErrorText}
            />
            <br />
            <SakCardInfo
              sakNumber={sakNumber}
              title={title}
              onCancelSak={onCancelSak}
              sakFail={sakFail}
              sakNotFound={sakNotFound}
              sakClosed={sakClosed}
              sakHjemmel={sakHjemmel}
              sakTilgang={sakTilgang}
              isLoading={isLoadingSak}
              showSak={showSak}
            />
          </ContrastBox>

          <ContrastBox noBorders backgroundColor="white">
            <GenerellInfomasjonCard />
          </ContrastBox>

          <TilsynsobjekterCardInfo
            onAddRemoveTilsynsobjekt={onAddRemoveTilsynsobjekt}
            tilsynsobjekter={currentKvittering.tilsynsobjekter}
            virksomhetsNavn={currentKvittering.virksomhetsNavn}
            virksomhetsOrgNr={currentKvittering.virksomhetsOrgNr}
          />
          {kvittering && kvittering.tilsynsobjekter?.length > 0 && (
            <KontrollpunkterSection
              isLoading={isLoading}
              tilsynsobjekter={kvittering.tilsynsobjekter}
              openTidligereKontrollpunkter={openTidligereKontrollpunkter}
            />
          )}

          <VirksomhetModal
            isOpen={isVirksomhetModalOpen}
            onCancel={onCancelVirksomhetModal}
            initialSearch={initialSearch(
              currentKvittering.virksomhetsNavn,
              sak.title
            )}
            onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt}
            midlertidigTilsynsobjektAktivitet={selectedAktivitet}
            midlertidigTilsynsobjektVirksomhet={
              midlertidigTilsynsObjektVirksomhet
            }
            onResetMidlertidigTilsynsojekt={() => {
              setMidlertidigTilsynsObjektVirksomhet({ navn: '', nummer: '' })
            }}
            onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
              setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
            }
            unselectedMidlertidigTilsynsobjekt={
              unselectedMidlertidigTilsynsobjekt
            }
            setIsVirksomhetModalOpen={setIsVirksomhetModalOpen}
          />
          {isAktivitetModalOpen && (
            <AktivitetModal
              isOpen={isAktivitetModalOpen}
              onCancel={() => onCancelAktivitetModal()}
              aktivitetOptions={aktivitetOptions}
              selectedAktivitet={selectedAktivitet}
              onSelect={onSelectAktivitet}
              onSearch={onSearchAktivitet}
              onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
                setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
              }
              unselectedMidlertidigTilsynsobjekt={
                unselectedMidlertidigTilsynsobjekt
              }
            />
          )}
        </Column>
      </Row>
    </Column>
  )
}
