import React, { ChangeEvent, useCallback, useState } from 'react'
import {
  Column,
  ContrastBox,
  ErrorBox,
  Header,
  LoadingDots,
  Row,
  SelectList,
  TextArea,
} from '@mattilsynet/mt-ui'
import './style.css'

import { ModalWrapper } from '../../components/modal-wrapper'
import { ITilsynstype } from '../../ducks/kvittering/types'
import { useDebounceFn } from '../../common/custom-hooks'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'

interface ITypeModalProps {
  isOpen: boolean
  onCancel: () => void
  currentType?: string
  currentTypeTilsynBeskrivelse?: string
  onClick: (val: string) => void
  errorAction: () => any
  onChangeBeskrivelse: (value: string) => void
  tilsynstyper: {
    loading: boolean
    loaded: boolean
    data: ITilsynstype[]
    error: string
  }
}

export const TypeTilsynModal = ({
  isOpen,
  onCancel,
  currentType,
  currentTypeTilsynBeskrivelse,
  onClick,
  onChangeBeskrivelse,
  tilsynstyper,
  errorAction,
}: ITypeModalProps) => {
  const debounce = useDebounceFn(2000)
  const { loading, loaded, data, error } = tilsynstyper
  const [isInputDirty, setIsInputDirty] = useState(() => false)
  const [beskrivelse, setBeskrivelse] = useState(currentTypeTilsynBeskrivelse)

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBeskrivelse(e.target.value)
    debounce(() => onChangeBeskrivelse(e.target.value))
  }

  const missingBeskrivelse =
    !currentTypeTilsynBeskrivelse ||
    currentTypeTilsynBeskrivelse.trim().length === 0

  const onCancelModal = useCallback(() => {
    if (missingBeskrivelse) {
      setIsInputDirty(true)
    }
    onCancel()
  }, [missingBeskrivelse, setIsInputDirty, onCancel])

  const renderContent = () => {
    if (error) {
      return (
        <Row center margin={5}>
          <ErrorBox
            errorText="Kunne ikke hente innhold."
            errorActionText="Prøv igjen..."
            errorAction={errorAction}
          />
        </Row>
      )
    } else if (loading && !loaded) {
      return (
        <Row center margin={5}>
          <LoadingDots />
        </Row>
      )
    } else {
      const dataList = data.map((type) => ({
        value: type.typeTilsynKey,
        label: type.beskrivelse,
      }))
      return (
        <Column padding={modalContentPadding}>
          <SelectList
            dataList={dataList}
            onClick={(value) => onClick(value)}
            selected={currentType ? [currentType] : []}
          />
          {currentType === 'ANNET' && (
            <Column spacing={2} padding={[0, 2, 0, 2]}>
              <ContrastBox noBorders backgroundColor="white">
                <Row className="subtitle">
                  <Header as={'h4'}>Beskrivelse</Header>
                </Row>
                <Row padding={[1, 0]} justify="center" align="center">
                  <TextArea
                    placeholder={'Beskriv typen tilsyn'}
                    onChange={onChange}
                    value={beskrivelse ?? ''}
                    errorText={
                      isInputDirty && missingBeskrivelse
                        ? `Type tilsyn må beskrives når typen "Annet" er valgt`
                        : ''
                    }
                    danger={isInputDirty && missingBeskrivelse}
                  />
                </Row>
              </ContrastBox>
            </Column>
          )}
        </Column>
      )
    }
  }

  return (
    <ModalWrapper
      className="type-tilsyn-modal"
      alignTop
      fullscreenMobile
      isOpen={isOpen}
      onCancel={onCancelModal}
      title="Velg type tilsyn"
    >
      {renderContent()}
      <ModalButtonsContainer>
        <ModalButton secondary={!currentType} onClick={onCancelModal}>
          {currentType ? 'Ferdig' : 'Lukk'}
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
