import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Grid,
  GridItem,
  Row,
  TextLink,
  Column,
  Button,
  RowItem,
  ContrastBox,
} from '@mattilsynet/mt-ui'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreState } from '../../reducers/types'
import './style.css'
import { VirksomhetModal } from '../../modals'
import SakCardInfo from '../../components/sak-card-info'
import TilsynsobjekterCardInfo from '../../components/tilsynsobjekter-card-info'
import SakSearch from '../../components/sak-search'
import { KontrollpunkterSection } from '../../features/kontrollpunkter'
import { Status } from '../../components/status'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { initialSearch } from './utils'
import {
  useMidlertidigTilsynsobjekt,
  useTypedSelector,
} from '../../common/custom-hooks'
import {
  offlineInSakAndTilsynsobjekt,
  offlineInTypeTilsynSelect,
  useSingleToast,
} from '../../common/toast'
import TypeTilsyn from '../../components/type-tilsyn'
import { SAVING } from '../../ducks/kvittering/types'
import { AktivitetModal } from '../../modals/aktivitet-modal'
import { tilsynsobjektActions } from '../../ducks/kvittering/tilsynsobjekt/actions'
import { useKvittering } from '../../features/kvitteringer'
import { KvitteringIdProvider } from '../../common/kvittering-context'
import { useViewsContext } from '.'
import { TidligereKontrollpunkter } from '../../features/tidligere-kontrollpunkter'

interface IForberedNewTilsynViewProps {
  sakNumberValue: string
  onChange: (e: any) => void
  onAddKvittering: () => void
  onStartTilsyn: (id: string) => void
  onWarnTilsynsObjekt: () => void
  onWarnSak: () => void
  onGoBack: () => void
  onAddSak: () => void
  onCancelSak: () => void
  showSak: boolean
  onWarnType: () => void
  onWarnTypeBeskrivelse: () => void
  kodeverkAktiviteterBehandlesIMats: any
}

export const ForberedNewTilsynView = ({
  sakNumberValue,
  onChange,
  onStartTilsyn,
  onWarnTilsynsObjekt,
  onWarnSak,
  onGoBack,
  onAddSak,
  onCancelSak,
  showSak,
  onWarnType,
  onWarnTypeBeskrivelse,
  kodeverkAktiviteterBehandlesIMats,
}: IForberedNewTilsynViewProps) => {
  const dispatch = useDispatch()
  const singleToast = useSingleToast()
  const { view, viewDispatch } = useViewsContext()
  const sak = useSelector((state: IStoreState) => state.sak)
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const {
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakTilgang,
    sakHjemmel,
  } = sak

  const [isVirksomhetModalOpen, setIsVirksomhetModalOpen] = useState(false)

  const [isStartTilsynClicked, setIsStartTilsynClicked] = useState(false)

  const currentKvittering = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.data
  )
  const {
    data: kvittering,
    isFetching,
    isLoading: isKvitteringLoading,
    refetch,
  } = useKvittering(currentKvittering.id)

  const error = useSelector((state: IStoreState) => state.kvittering.error)

  const status = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.status
  )

  const isLoading = useSelector(
    (state: IStoreState) => state.kvittering.loading
  )

  const isLoadingSak = useSelector((state: IStoreState) => state.sak.loading)

  const [hasFetchedKvittering, setHasFetchedKvittering] = useState(false)

  // Slett isFetchingRef og refetch-useEffecten når vi er ferdige
  // med å konvertere til tanstack
  const isFetchingRef = useRef(isFetching)
  useEffect(() => {
    isFetchingRef.current = isFetching
  }, [isFetching])

  useEffect(() => {
    if (!isFetchingRef.current && currentKvittering.id !== '') {
      refetch()
    }
  }, [currentKvittering, refetch])

  const {
    isAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt,
  } = useMidlertidigTilsynsobjekt(
    kodeverkAktiviteterBehandlesIMats,
    setIsVirksomhetModalOpen
  )

  useEffect(() => {
    if (currentKvittering.id) {
      setHasFetchedKvittering(true)
    }
  }, [currentKvittering])

  useEffect(() => {
    if (hasFetchedKvittering) {
      dispatch(tilsynsobjektActions.fetchTilsynsobjekter())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedKvittering])

  const openTidligereKontrollpunkter = useCallback(
    () => viewDispatch('tidligereKontrollpunkter'),
    [viewDispatch]
  )

  const closeTidligereKontrollpunkter = useCallback(
    () => viewDispatch('default'),
    [viewDispatch]
  )

  const onCancelVirksomhetModal = () => {
    setIsVirksomhetModalOpen(false)
  }

  const onClickTilsyn = () => {
    setIsStartTilsynClicked(true)
    if (
      ((currentKvittering.typeTilsyn &&
        currentKvittering.typeTilsyn !== 'ANNET') ||
        (currentKvittering.typeTilsyn === 'ANNET' &&
          currentKvittering.typeTilsynBeskrivelse)) &&
      currentKvittering.tilsynsobjekter &&
      currentKvittering.tilsynsobjekter.length > 0 &&
      currentKvittering.id &&
      ((currentKvittering.noarksakSekvensnummer &&
        currentKvittering.noarksakAar) ||
        !sakNumberValue)
    ) {
      return onStartTilsyn(currentKvittering.id)
    }
    if (
      sakNumberValue.length > 0 &&
      !(
        currentKvittering.noarksakSekvensnummer && currentKvittering.noarksakAar
      )
    ) {
      return onWarnSak()
    }

    if (!currentKvittering.typeTilsyn) {
      return onWarnType()
    }

    if (
      currentKvittering.typeTilsyn === 'ANNET' &&
      (!currentKvittering.typeTilsynBeskrivelse ||
        currentKvittering.typeTilsynBeskrivelse.trim().length === 0)
    ) {
      return onWarnTypeBeskrivelse()
    }

    return onWarnTilsynsObjekt()
  }

  const onAddRemoveTilsynsobjekt = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setIsVirksomhetModalOpen(true)
  }

  const onChangeTypeTilsyn = (typeValue) => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect())
    }

    dispatch(kvitteringActions.setTypeTilsyn(typeValue))
    dispatch(kvitteringActions.setStatus(SAVING))
  }

  if (view === 'tidligereKontrollpunkter') {
    return (
      <TidligereKontrollpunkter
        tilsynsobjekter={currentKvittering.tilsynsobjekter}
        kvitteringId={currentKvittering.id ?? ''}
        onCancel={closeTidligereKontrollpunkter}
      />
    )
  }

  return (
    <KvitteringIdProvider value={kvittering?.id ?? ''}>
      <Grid>
        <VirksomhetModal
          isOpen={isVirksomhetModalOpen}
          onCancel={onCancelVirksomhetModal}
          initialSearch={initialSearch(
            currentKvittering.virksomhetsNavn,
            sak.title
          )}
          onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt}
          midlertidigTilsynsobjektAktivitet={selectedAktivitet}
          midlertidigTilsynsobjektVirksomhet={
            midlertidigTilsynsObjektVirksomhet
          }
          onResetMidlertidigTilsynsojekt={() => {
            setMidlertidigTilsynsObjektVirksomhet({ navn: '', nummer: '' })
          }}
          onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
            setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
          }
          unselectedMidlertidigTilsynsobjekt={
            unselectedMidlertidigTilsynsobjekt
          }
          setIsVirksomhetModalOpen={setIsVirksomhetModalOpen}
        />
        {isAktivitetModalOpen && (
          <AktivitetModal
            isOpen={isAktivitetModalOpen}
            onCancel={() => onCancelAktivitetModal()}
            aktivitetOptions={aktivitetOptions}
            selectedAktivitet={selectedAktivitet}
            onSelect={onSelectAktivitet}
            onSearch={onSearchAktivitet}
            onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
              setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
            }
            unselectedMidlertidigTilsynsobjekt={
              unselectedMidlertidigTilsynsobjekt
            }
          />
        )}

        <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
          <Row>
            <TextLink leftArrow onClick={onGoBack}>
              Tilbake
            </TextLink>
          </Row>
        </GridItem>
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
          <Grid id="forbered-new-tilsyn">
            <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
              <Column spacing={3} fluid>
                <Row className="forbered-title">
                  <h2>Forbered nytt tilsyn</h2>
                </Row>
                <Row>
                  <Column spacing={2} fluid>
                    <TypeTilsyn
                      onChange={onChangeTypeTilsyn}
                      currentType={currentKvittering.typeTilsyn}
                      currentTypeTypeTilsynBeskrivelse={
                        currentKvittering.typeTilsynBeskrivelse
                      }
                    />
                    <ContrastBox noBorders backgroundColor="white">
                      <Row margin={[0, 0, 1, 0]} className="forbered-subtitle">
                        <h4>Legg til sak</h4>
                      </Row>
                      <SakSearch
                        onAddSak={onAddSak}
                        onChange={onChange}
                        sakNumberValue={sakNumberValue}
                        errorText={
                          sakNumberValue.length > 0 &&
                          isStartTilsynClicked &&
                          !(
                            currentKvittering.noarksakSekvensnummer &&
                            currentKvittering.noarksakAar
                          )
                            ? 'Saken må legges til eller tekstfeltet må være tomt'
                            : ''
                        }
                      />
                      <br />
                      <SakCardInfo
                        sakNumber={sakNumber}
                        title={title}
                        onCancelSak={onCancelSak}
                        sakFail={sakFail}
                        sakNotFound={sakNotFound}
                        sakClosed={sakClosed}
                        isLoading={isLoadingSak}
                        sakTilgang={sakTilgang}
                        sakHjemmel={sakHjemmel}
                        showSak={showSak}
                      />
                    </ContrastBox>

                    <TilsynsobjekterCardInfo
                      onAddRemoveTilsynsobjekt={onAddRemoveTilsynsobjekt}
                      tilsynsobjekter={currentKvittering.tilsynsobjekter}
                      virksomhetsNavn={currentKvittering.virksomhetsNavn}
                      virksomhetsOrgNr={currentKvittering.virksomhetsOrgNr}
                    />
                    {kvittering && kvittering.tilsynsobjekter.length > 0 && (
                      <KontrollpunkterSection
                        isLoading={isKvitteringLoading}
                        tilsynsobjekter={kvittering.tilsynsobjekter}
                        openTidligereKontrollpunkter={
                          openTidligereKontrollpunkter
                        }
                      />
                    )}
                    <Row justify="space-between" align="center">
                      <RowItem>
                        <TextLink leftArrow onClick={onGoBack}>
                          Tilbake
                        </TextLink>
                      </RowItem>
                      <RowItem>
                        <Button secondary arrow onClick={onClickTilsyn}>
                          Start tilsyn
                        </Button>
                      </RowItem>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </GridItem>
          </Grid>
        </GridItem>
        <Status status={status} error={error} isLoading={isLoading} />
      </Grid>
    </KvitteringIdProvider>
  )
}
