import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './style.css'
import { kodeverk, toast } from '@mattilsynet/mt-common'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { getSak, clearSak } from '../../ducks/sak/actions'
import { ForberedNewTilsynView } from './view'
import { onKeyDown } from '../../helpers'
import { SAVING } from '../../ducks/kvittering/types'
import {
  offlineInSakAndTilsynsobjekt,
  useSingleToast,
} from '../../common/toast'
import { createViewContext } from '../../features/views'
import { useGoBack, useTKNavigate } from '../../common/navigation'

const [ViewsProvider, useViewsContext] = createViewContext<
  'default' | 'tidligereKontrollpunkter'
>('default')

export { useViewsContext }

const ForberedNewTilsyn: React.FC<{ match?: any }> = () => {
  const dispatch = useDispatch()
  const navigate = useTKNavigate()
  const goBack = useGoBack()
  const singleToast = useSingleToast()

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const isSakValid = useTypedSelector(
    (state: IStoreState) =>
      !(state.sak.sakNotFound || state.sak.sakClosed || state.sak.sakFail)
  )

  const kodeverkAktiviteterBehandlesIMats = kodeverk.hooks.useKodeverk(
    'AKTIVITET',
    { filter: 'H' }
  )

  const [sakNumberValue, setSakNumberValue] = useState('')
  const [showSak, setShowSak] = useState(false)

  const onAddKvittering = () => {
    if (isSakValid) {
      return
    }

    dispatch(
      toast.actions.showToast({
        text: 'Saken du har lagt til kan ikke brukes. Du må enten fjerne saken eller legge til en ny sak',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER',
      })
    )
  }

  const onWarnTilsynsObjekt = () => {
    dispatch(
      toast.actions.showToast({
        text: 'Du må legge til et tilsynsobjekt før du kan starte tilsynet.',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER',
      })
    )
  }

  const onWarnSak = () => {
    dispatch(
      toast.actions.showToast({
        text: 'Du har lagt inn et saksnummer i søkefeltet uten å søke opp og legge til saken',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER',
      })
    )
  }

  const onWarnType = () => {
    dispatch(
      toast.actions.showToast({
        text: 'Du må velge type tilsyn før du kan starte tilsynet',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER',
      })
    )
  }

  const onWarnTypeBeskrivelse = () => {
    dispatch(
      toast.actions.showToast({
        text: 'Du må legge til beskrivelse av type tilsyn før du kan starte tilsynet',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER',
      })
    )
  }

  const onStartTilsyn = (id: string) => {
    navigate(`/start-tilsyn/${id}`)
  }

  const onChange = (e) => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setSakNumberValue(onKeyDown(e, sakNumberValue))
  }

  const onAddSak = () => {
    if (sakNumberValue.length > 0) {
      setShowSak(true)
      if (isOffline) {
        return singleToast.showToast(offlineInSakAndTilsynsobjekt())
      }
      dispatch(getSak(sakNumberValue))
      dispatch(kvitteringActions.setStatus(SAVING))
    }
  }

  const onCancelSak = () => {
    setShowSak(false)
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }

    dispatch(clearSak())
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        noarksakSekvensnummer: '',
        noarksakAar: '',
      })
    )
    setSakNumberValue('')
  }

  const onGoBack = () => {
    dispatch(kvitteringActions.clearCurrentKvittering())
    dispatch(clearSak())
    goBack()
  }

  return (
    <ViewsProvider>
      <ForberedNewTilsynView
        sakNumberValue={sakNumberValue}
        onChange={onChange}
        onWarnTilsynsObjekt={onWarnTilsynsObjekt}
        onWarnSak={onWarnSak}
        onStartTilsyn={onStartTilsyn}
        onAddKvittering={onAddKvittering}
        onGoBack={onGoBack}
        onAddSak={onAddSak}
        onCancelSak={onCancelSak}
        showSak={showSak}
        onWarnType={onWarnType}
        onWarnTypeBeskrivelse={onWarnTypeBeskrivelse}
        kodeverkAktiviteterBehandlesIMats={kodeverkAktiviteterBehandlesIMats}
      />
    </ViewsProvider>
  )
}

export default ForberedNewTilsyn
