import React, { useState, useEffect, useRef, useContext } from 'react'
import { Row, RowItem, Column, Button, ThemeContext } from '@mattilsynet/mt-ui'
import './style.css'
import {
  IIdentitetItem,
  IVirksomhetTilsynsobjekt,
} from '../../ducks/virksomhet-search/types'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { TilsynsobjektDeleteModal } from '../../modals/tilsynsobjekt-delete-modal'
import { useDispatch } from 'react-redux'
import { tilsynsobjektActions } from '../../ducks/kvittering/tilsynsobjekt/actions'
import { Avdelingstilhorighet } from '../../features/avdelingstilhorighet'

interface ITilsynsobjektItemProps {
  onSelectTilsynsobjekt: any
  tilsynsobjekt: IVirksomhetTilsynsobjekt
  isEnabled: boolean
  isAlreadySelected?: boolean
  orgNr: string
  index: number
  setIsVirksomhetModalOpen: (isOpen: boolean) => void
}

export const TilsynsobjektItem = ({
  onSelectTilsynsobjekt,
  tilsynsobjekt,
  isEnabled,
  isAlreadySelected,
  orgNr,
  index,
  setIsVirksomhetModalOpen,
}: ITilsynsobjektItemProps) => {
  const [isSelected, setIsSelected] = useState(() => isAlreadySelected)
  const isMounted = useRef(false)
  const theme = useContext(ThemeContext)
  const tilsynsobjekter = useTypedSelector(
    (state: IStoreState) =>
      state.kvittering.selectedKvittering.data.tilsynsobjekter
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isMounted.current) {
      onSelectTilsynsobjekt(tilsynsobjekt, isSelected, orgNr)
    } else {
      isMounted.current = true
    }
  }, [isSelected])

  useEffect(() => {
    if (tilsynsobjekter.length === 0) {
      setIsSelected(false)
    }
  }, [tilsynsobjekter.length])

  const onClickTilsynsobjekt = () => {
    const hasKontrollpunkt = tilsynsobjekter?.find(
      (t) =>
        t.idstring === tilsynsobjekt.idstring && t.kontrollpunkter.length > 0
    )
    if (hasKontrollpunkt) {
      setIsDeleteModalOpen(true)
    } else {
      setIsSelected((isSelected) => !isSelected)
    }
  }

  const isFirst = tilsynsobjekter[0]?.idstring === tilsynsobjekt.idstring

  return (
    <>
      <Row
        id="tilsynsobjekt-item"
        padding={3}
        smColumn
        backgroundColor={index % 2 ? theme.white : theme.gray7}
      >
        <RowItem flex={3}>
          <Column spacing={1} align="center" fluid>
            <p className="activity-header">{tilsynsobjekt.navn}</p>
            <p className="activity-subtitle">
              {tilsynsobjekt.aktivitet.beskrivelse}
            </p>
            {tilsynsobjekt.identiteter?.map(
              (identitetstype: IIdentitetItem) => (
                <Column spacing={0.5} align="center" key={identitetstype.verdi}>
                  <Row>
                    <p className="activity-info">
                      {identitetstype.identitetstype.beskrivelse}:{' '}
                      {identitetstype.verdi}
                    </p>
                  </Row>
                </Column>
              )
            )}
          </Column>
        </RowItem>

        <RowItem flex={1} className="button-container" alignSelf={'center'}>
          <Button
            secondary={!isSelected}
            disabled={!isEnabled}
            fill
            onClick={onClickTilsynsobjekt}
          >
            {isSelected ? 'Tilsynsobjekt valgt' : 'Velg tilsynsobjekt'}
          </Button>
        </RowItem>

        {isFirst && isSelected && <Avdelingstilhorighet />}
      </Row>

      {isDeleteModalOpen && (
        <TilsynsobjektDeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          onDelete={() => {
            setIsSelected(false)
            dispatch(
              tilsynsobjektActions.deleteTilsynsObjekt(tilsynsobjekt, false)
            )
            setIsVirksomhetModalOpen(true)
          }}
          tilsynsobjekt={tilsynsobjekt}
          onCancel={() => {
            setIsDeleteModalOpen(false)
            setIsVirksomhetModalOpen(true)
          }}
        />
      )}
    </>
  )
}
