import { IKvitteringData } from '../../../ducks/kvittering/types'
import { queryClient } from '../../../api/query-client'
import { Draft, Immutable, produce } from 'immer'
import { mergeKeyWith } from '../../../common/query'

const getKvitteringKeys = () => {
  const keys = {
    all: [{ entity: 'kvitteringer' }] as const,
    kvitteringId: (kvitteringId: string) =>
      mergeKeyWith(keys.all, { kvitteringId }),
    sammenstilling: (kvitteringId: string, url: string) =>
      mergeKeyWith(keys.all, { type: 'sammenstilling', kvitteringId, url }),
    tilsynstyper: () => mergeKeyWith(keys.all, { type: 'tilsynstyper' }),
  } as const

  return keys
}

const kvitteringKeys = getKvitteringKeys()

export type KvitteringKeys = typeof kvitteringKeys

export { kvitteringKeys, kvitteringQueryHelpers }

const kvitteringQueryHelpers = {
  getKvitteringCache: (kvitteringId: string): Immutable<IKvitteringData> => {
    const kvittering = queryClient.getQueryData<Immutable<IKvitteringData>>(
      kvitteringKeys.kvitteringId(kvitteringId)
    )

    if (!kvittering) {
      throw new Error(`Kvittering ${kvitteringId} not found in cache`)
    }

    return kvittering
  },
  updateKvitteringCache: (
    kvitteringId: string,
    updater: (draft: Draft<IKvitteringData>) => void
  ) => {
    queryClient.setQueryData<IKvitteringData>(
      kvitteringKeys.kvitteringId(kvitteringId),
      (kvittering) => {
        if (!kvittering) {
          return kvittering
        }

        return produce(kvittering, (draft) => updater(draft))
      }
    )
  },
  invalidateKvittering: (kvitteringId: string) =>
    queryClient.invalidateQueries({
      queryKey: kvitteringKeys.kvitteringId(kvitteringId),
    }),
  cancelKvitteringQuery: (kvitteringId: string) =>
    queryClient.cancelQueries({
      queryKey: kvitteringKeys.kvitteringId(kvitteringId),
    }),
}
