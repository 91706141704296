import { toast } from '@mattilsynet/mt-common'
import { ROOT_URL_TILSYNKVITTERING } from './epic'
import { IStoreState } from '../../reducers/types'
import { IFordelingInformasjon, IKvitteringData } from './types'
import { ICommonApi } from '../../api'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, take, toArray } from 'rxjs/operators'
import { IAnsatt } from '../ansatte/types'
import { kvitteringActions } from './actions'
import { formatAllKvitteringer } from './utils'
import { ENDPOINT } from '../../constants'

export const kvitteringDeleteSuccessToast = toast.actions.showToast({
  text: 'Tilsynet ble slettet',
  timeoutSeconds: 5,
  dismissible: true,
  type: 'SUCCESS',
})

export const kvitteringDeleteFailedToast = toast.actions.showToast({
  text: 'Kunne ikke slette tilsyn',
  timeoutSeconds: 3,
  dismissible: true,
  type: 'DANGER',
})

export const kvitteringFerdigstillFailedToast = toast.actions.showToast({
  text: 'Kunne ikke ferdigstille tilsyn',
  timeoutSeconds: 3,
  dismissible: true,
  type: 'DANGER',
})

export const kvitteringArkivereFailedToast = toast.actions.showToast({
  text: 'Kunne ikke arkivere tilsyn',
  timeoutSeconds: 3,
  dismissible: true,
  type: 'DANGER',
})

export const fetchKvitteringById$ =
  (commonApi: ICommonApi, state: IStoreState) =>
  (id) =>
  (obs$: Observable<any>) => {
    return obs$.pipe(
      commonApi.get(
        `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}`,
        state
      )
    )
  }

export const getFordelingInformasjon =
  (commonApi: ICommonApi, state: IStoreState) =>
  (allKvitteringer: IKvitteringData[]) =>
    of(allKvitteringer).pipe(
      mergeMap((kvitteringer: IKvitteringData[]) => kvitteringer),
      mergeMap((kvittering: IKvitteringData) =>
        of(state).pipe(
          commonApi.get(
            `${ROOT_URL_TILSYNKVITTERING}/tilsynskvitteringer/fordeling/${kvittering.id}`,
            state
          ),
          map((resp) => resp?._embedded?.fordelinger ?? []),
          map((fordelingData) => fordelingData[0]),

          mergeMap((fordeling: IFordelingInformasjon) => {
            if (
              fordeling?.fordelingstype === undefined ||
              fordeling?.forrigeEier === undefined
            ) {
              return of(fordeling)
            }

            return of(fordeling).pipe(
              commonApi.get(
                `/api/orgenhet-api/ansatte/${fordeling?.forrigeEier}`,
                state
              ),
              map(
                (resp: IAnsatt) =>
                  ({
                    ...fordeling,
                    forrigeEierNavn: resp?.navn,
                  }) as IFordelingInformasjon
              ),
              catchError(() => EMPTY)
            )
          }),

          map((fordeling: IFordelingInformasjon) =>
            fordeling?.tilsynskvitteringId.toString() === kvittering.id
              ? { ...kvittering, fordelingInfo: fordeling }
              : kvittering
          ),
          catchError(() => EMPTY)
        )
      ),
      take(allKvitteringer.length),
      toArray(),
      mergeMap((resultat) =>
        of(
          kvitteringActions.fetchKvitteringerSuccess(
            formatAllKvitteringer(resultat)
          )
        )
      ),
      catchError(() => EMPTY)
    )
