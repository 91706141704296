import { useKvittering } from '../../kvitteringer'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'

export { useTilsynsobjekter }

const onlySimpleKontrollpunkter = (tilsynsobjekt: ITilsynsobjekt) =>
  ({
    ...tilsynsobjekt,
    kontrollpunkter: tilsynsobjekt.kontrollpunkter.map((kp) => ({
      id: kp.id,
      sorteringsrekkefoelge: kp.sorteringsrekkefoelge,
    })),
  }) as ITilsynsobjekt

/**
 * Get tilsynsobjekter for a given kvittering (w/o kontrollpunkter).
 *
 * Excludes kontrollpunkter to improve performance.
 *
 * @param kvitteringId
 */
const useTilsynsobjekter = (kvitteringId: string) =>
  useKvittering(kvitteringId, {
    select: (kvittering) => {
      return kvittering.tilsynsobjekter?.map(onlySimpleKontrollpunkter) ?? []
    },
  })
