import React from 'react'
import { Column, Row, Button, ContrastBox } from '@mattilsynet/mt-ui'
import './style.css'
import { ITilsynsobjekt } from '../../ducks/kvittering/tilsynsobjekt/types'
import { Avdelingstilhorighet } from '../../features/avdelingstilhorighet'

interface ITilsynsobjekterCardInfoProps {
  onAddRemoveTilsynsobjekt: () => void
  tilsynsobjekter: ITilsynsobjekt[]
  virksomhetsNavn?: string
  virksomhetsOrgNr?: string
}

const TilsynsobjekterCardInfo = ({
  onAddRemoveTilsynsobjekt,
  tilsynsobjekter,
  virksomhetsNavn,
  virksomhetsOrgNr,
}: ITilsynsobjekterCardInfoProps) => {
  const areSelectedTilsynsobjekter =
    tilsynsobjekter && tilsynsobjekter.length > 0
  const renderTilsynsobjekter = () => {
    if (!areSelectedTilsynsobjekter) return

    const aktivitetsBeskrivelse = (tilsynsobjekt) =>
      tilsynsobjekt.aktivitetsBeskrivelse
        ? `(${tilsynsobjekt.aktivitetsBeskrivelse})`
        : ''

    const orgOrPerson = virksomhetsOrgNr
      ? `Org nr underenhet: ${virksomhetsOrgNr}`
      : `Privatperson`

    const navn = tilsynsobjekter.some((t) => t.midlertidig)
      ? tilsynsobjekter[0].virksomhetsNavn
      : virksomhetsNavn

    return (
      <Column spacing={1}>
        <Row className="info-header">{navn}</Row>
        <Row>{orgOrPerson}</Row>
        {tilsynsobjekter.map((tilsynsobjekt: ITilsynsobjekt) => (
          <Row
            key={
              tilsynsobjekt.idstring +
              tilsynsobjekt.tilsynsobjektId +
              tilsynsobjekt.id
            }
          >
            {`${tilsynsobjekt.navn} ${aktivitetsBeskrivelse(tilsynsobjekt)}`}
          </Row>
        ))}
      </Column>
    )
  }
  return (
    <div id="tilsynsobjekter-card-info">
      <ContrastBox noBorders backgroundColor="white">
        <Column fluid spacing={2.5}>
          <Row className="subtitle">
            <h3>Tilsynsobjekt</h3>
          </Row>
          {renderTilsynsobjekter()}

          {tilsynsobjekter.length > 0 && <Avdelingstilhorighet />}

          <Row justify="center" className="button-add">
            <Button fill secondary onClick={onAddRemoveTilsynsobjekt}>
              {areSelectedTilsynsobjekter
                ? 'Legg til/fjern tilsynsobjekt'
                : 'Legg til tilsynsobjekt'}
            </Button>
          </Row>
        </Column>
      </ContrastBox>
    </div>
  )
}

export default TilsynsobjekterCardInfo
