import React, { useState, useEffect, useRef, useContext } from 'react'
import { Row, RowItem, Column, Button, ThemeContext } from '@mattilsynet/mt-ui'
import './style.css'
import {
  IVirksomhetItem,
  IVirksomhetTilsynsobjekt,
} from '../../ducks/virksomhet-search/types'
import { IAktivitet } from '../../ducks/kvittering/types'
import { TilsynsobjektDeleteModal } from '../../modals/tilsynsobjekt-delete-modal'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { ITilsynsobjekt } from '../../ducks/kvittering/tilsynsobjekt/types'

interface IMidlertidigTilsynsobjektItemProps {
  onSelectTilsynsobjekt: (activity, isSelected: boolean, orgId: string) => void
  midlertidigTilsynsobjekt: IVirksomhetTilsynsobjekt
  isEnabled: boolean
  isAlreadySelected?: boolean
  organisasjonsnummer: string
  index: number
  virksomhet: IVirksomhetItem
  aktivitet?: IAktivitet
  setIsVirksomhetModalOpen: (isOpen: boolean) => void
}

export const MidlertidigTilsynsobjektItem = ({
  onSelectTilsynsobjekt,
  midlertidigTilsynsobjekt,
  isEnabled,
  isAlreadySelected,
  organisasjonsnummer,
  index,
  virksomhet,
  aktivitet,
  setIsVirksomhetModalOpen,
}: IMidlertidigTilsynsobjektItemProps) => {
  const [isSelected, setIsSelected] = useState(() => isAlreadySelected)

  const isMounted = useRef(false)

  const theme = useContext(ThemeContext)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const tilsynsobjekter = useTypedSelector(
    (state: IStoreState) =>
      state.kvittering.selectedKvittering.data.tilsynsobjekter
  )

  useEffect(() => {
    if (isMounted.current) {
      onSelectTilsynsobjekt(
        {
          aktivitetsBeskrivelse: aktivitet?.label,
          midlertidig: true,
          aktivitetsId: aktivitet?.value,
          navn: 'Midlertidig Tilsynsobjekt',
          virksomhetsnavn: virksomhet.navn,
          virksomhetsNummer: organisasjonsnummer,
        },
        isSelected!,
        organisasjonsnummer
      )
    } else {
      isMounted.current = true
    }
  }, [isSelected])

  const onClickTilsynsobjekt = () => {
    const hasKontrollpunkt = tilsynsobjekter?.find(
      (t) =>
        t.id === midlertidigTilsynsobjekt.id && t.kontrollpunkter.length > 0
    )
    if (hasKontrollpunkt) {
      setIsDeleteModalOpen(true)
    } else {
      setIsSelected((isSelected) => !isSelected)
    }
  }

  return (
    <>
      <Row
        id="tilsynsobjekt-item"
        padding={3}
        smColumn
        backgroundColor={index % 2 ? theme.white : theme.gray7}
      >
        <RowItem flex={3}>
          <Column justify={'center'} align={'center'} spacing={1}>
            <p className="activity-header">{'MIDLERTIDIG TILSYNSOBJEKT'}</p>
            <p>
              {(midlertidigTilsynsobjekt as unknown as ITilsynsobjekt)
                .aktivitetsBeskrivelse || aktivitet?.label}
            </p>
          </Column>
        </RowItem>
        <RowItem flex={1} className="button-container" alignSelf={'center'}>
          <Button
            secondary={!isSelected}
            disabled={!isEnabled}
            fill
            onClick={onClickTilsynsobjekt}
          >
            {isSelected
              ? 'Midlertidig tilsynsobjekt valgt'
              : 'Velg midlertidig tilsynsobjekt'}
          </Button>
        </RowItem>
      </Row>
      {isDeleteModalOpen && (
        <TilsynsobjektDeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          onDelete={() => {
            setIsSelected(false)
            setIsVirksomhetModalOpen(true)
          }}
          tilsynsobjekt={midlertidigTilsynsobjekt}
          onCancel={() => {
            setIsDeleteModalOpen(false)
            setIsVirksomhetModalOpen(true)
          }}
        />
      )}
    </>
  )
}
