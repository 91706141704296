import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Row, IconButton, ExitIcon, Column } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'
import { ModalButtonsContainer } from '../modal-buttons-container'
import { ModalButton } from '../modal-button'
import { modalContentPaddingLarge } from '../modal-buttons-container/constants'
import { useTKNavigate } from '../../common/navigation'

const GoToForsiden = () => {
  const navigate = useTKNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const withMargin = useMediaQuery({ query: '(min-width: 720px)' })

  return (
    <>
      <Row margin={[withMargin ? 1.5 : 0, 0]} className="forsiden-user-menu">
        <IconButton
          onClick={() => navigate('/')}
          icon={<ExitIcon />}
          ariaLabel="Til forsiden"
          textColor="white"
          small
        >
          <p>Til forsiden</p>
        </IconButton>
      </Row>

      {/* Leaving this, if caused to be troublesome not having it */}
      <ModalWrapper
        onCancel={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        fullscreenMobile
      >
        <Column padding={modalContentPaddingLarge} spacing={5} margin={3}>
          <Column spacing={4} margin={[0, 2]} align="center">
            <Row>
              Du er i ferd med å forlate utfyllingen av tilsynskvitteringen.
            </Row>
            <Row>Det du har fylt ut er lagret og du kan fortsette senere.</Row>
            <Row>
              <b>Er du sikker på at du vil forlate utfyllingen?</b>
            </Row>
          </Column>
        </Column>
        <ModalButtonsContainer>
          <ModalButton secondary onClick={() => setIsModalOpen(false)}>
            Nei, jeg vil fortsette utfyllingen
          </ModalButton>
          <ModalButton onClick={() => navigate('/')}>
            Ja, jeg vil forlate utfyllingen
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>
  )
}

export default GoToForsiden
