import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ErrorBox, Grid, GridItem, Row } from '@mattilsynet/mt-ui'
import { IStoreState } from '../../reducers/types'
import { useDebounce, useTypedSelector } from '../../common/custom-hooks'
import './style.css'

import { swipeToEnum, useSlider } from '../../components/slider'
import {
  SakOgTilsynsobjektSlide,
  DeltakereSlide,
  VeiledningSlide,
  ObservasjonerSlide,
  SammenstillingSlide,
} from './slides'

import { useDispatch } from 'react-redux'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { LoadingModal } from '../../modals'
import { ArkiveringSlide } from './slides/arkivering-slide'

import { checkValidation } from './utils'
import {
  dangerToast,
  useSingleToast,
  offlineInArkiveringSlide,
  sakITekstfelt,
} from '../../common/toast'
import { getSak } from '../../ducks/sak/actions'
import {
  IKvitteringData,
  kvitteringStatusEnum,
} from '../../ducks/kvittering/types'
import { toast } from '@mattilsynet/mt-common'
import KvitteringNavigator from '../../components/kvittering-navigator'
import { GalleryPage } from '../../components/gallery-page'
import { FullImagePage } from '../full-image'
import { useKvitteringId } from '../../common/kvittering-context'
import { TidligereKontrollpunkter } from '../../features/tidligere-kontrollpunkter'
import { useViewsContext } from '.'
import { isArkivertStatus, isFerdigstiltStatus } from '../../utils/kvittering'
import { KvitteringPage } from '../../components/kvittering-page'
import { TilsynsobjektinfoPage } from '../../components/tilsynsobjektinfo-page'
import { useTKNavigate, useReplaceQuery } from '../../common/navigation'
import { kvitteringQueryHelpers } from '../../features/kvitteringer/queries/helpers'

const INVALID = 'INVALID'
const CHECKING = 'CHECKING'
const ERROR = 'ERROR'
const OK = 'OK'

const TilsynskvitteringPages = {
  TILSYNSKVITTERING: 0,
  GALLERI: 1,
  TILSYNSOBJEKTINFO: 2,
}

export const StartTilsynView = () => {
  const dispatch = useDispatch()
  const navigate = useTKNavigate()
  const replaceQuery = useReplaceQuery()
  const singleToast = useSingleToast()
  const kvitteringId = useKvitteringId()

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const selectedKvittering = useTypedSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering
  )

  const selectedKvitteringStatus = selectedKvittering.status

  const initialCurrentSlide = Number(
    new URLSearchParams(location.search).get('currentSlide')
  )

  // Fix later
  const loading = false
  // const loading = useTypedSelector(
  //   (state: IStoreState) => state.kvittering.selectedKvittering.loading
  // )

  const [blockContinueFromZero, setBlockContinueFromZero] = useState(false)

  const [formStatus, setFormStatus] = useState(INVALID)

  const { view, viewDispatch } = useViewsContext()
  const onBack = useCallback(() => viewDispatch('default'), [viewDispatch])

  const [isValidationDirty, setIsValidationDirty] = useState(false)

  const slides = useMemo(
    () => [
      <SakOgTilsynsobjektSlide
        key={'Sak'}
        blockContinueFromZero={blockContinueFromZero}
      />,

      <DeltakereSlide key={'Deltakere'} />,

      <ObservasjonerSlide key={'Observasjoner'} />,

      <VeiledningSlide key={'Veiledninger'} />,

      <SammenstillingSlide
        key="Sammenstilling"
        shouldValidate={isValidationDirty}
      />,

      <ArkiveringSlide key="Arkivering" />,
    ],
    [blockContinueFromZero, isValidationDirty]
  )

  const [currentSlide, goToNext, goToPrevious, goToSlide, swipeTo, onSwipe] =
    useSlider(
      slides.length,
      initialCurrentSlide > 0 && initialCurrentSlide < 5
        ? initialCurrentSlide
        : 0
    )

  const [currentPage, , , goToPage] = useSlider(2, 0)

  const onGoToSlide = useCallback(
    (slide: number) => {
      if (currentSlide === 0) {
        setBlockContinueFromZero(true)
        if (
          selectedKvittering.data.sakNumberValue &&
          !(
            selectedKvittering.data.noarksakSekvensnummer &&
            selectedKvittering.data.noarksakAar
          )
        ) {
          return dispatch(toast.actions.showToast(sakITekstfelt()))
        }
      }

      if (slide < 5) {
        goToSlide(slide)
        return
      }

      if (isOffline) {
        return singleToast.showToast(offlineInArkiveringSlide())
      }
      setFormStatus(CHECKING)
      dispatch(kvitteringActions.fetchSammenstilling())
    },
    [
      dispatch,
      goToSlide,
      currentSlide,
      selectedKvittering.data.sakNumberValue,
      selectedKvittering.data.noarksakSekvensnummer,
      selectedKvittering.data.noarksakAar,
      isOffline,
      singleToast,
    ]
  )

  const showLoadingModal =
    useDebounce(formStatus === CHECKING, 200) && formStatus === CHECKING

  const onGoToNext = useCallback(() => {
    if (currentSlide === 0) {
      setBlockContinueFromZero(true)
      if (
        selectedKvittering.data.sakNumberValue &&
        !(
          selectedKvittering.data.noarksakSekvensnummer &&
          selectedKvittering.data.noarksakAar
        )
      ) {
        return dispatch(toast.actions.showToast(sakITekstfelt()))
      }
    }

    if (currentSlide !== 4) {
      goToNext()
      return
    }

    if (isOffline) {
      return singleToast.showToast(offlineInArkiveringSlide())
    }
    setFormStatus(CHECKING)
    dispatch(kvitteringActions.fetchSammenstilling())
  }, [
    dispatch,
    goToNext,
    currentSlide,
    selectedKvittering.data.sakNumberValue,
    selectedKvittering.data.noarksakSekvensnummer,
    selectedKvittering.data.noarksakAar,
    isOffline,
    singleToast,
  ])

  const [hasFetchedKvittering, setHasFetchedKvittering] = useState(false)

  const renderKvitteringPage = useCallback(() => {
    switch (currentPage) {
      case TilsynskvitteringPages.GALLERI:
        return (
          <GridItem xl={[2, -2]} lg={[2, -2]} md={[1, -1]} sm={[1, -1]}>
            <GalleryPage />
          </GridItem>
        )
      case TilsynskvitteringPages.TILSYNSOBJEKTINFO:
        return (
          <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
            <TilsynsobjektinfoPage />
          </GridItem>
        )
      case TilsynskvitteringPages.TILSYNSKVITTERING:
      default:
        return (
          <GridItem xl={[2, -2]} lg={[2, -2]} md={[1, -1]} sm={[1, -1]}>
            <KvitteringPage
              slides={slides}
              currentSlide={currentSlide}
              isLoading={!(selectedKvittering.data.id && hasFetchedKvittering)}
            />
          </GridItem>
        )
    }
  }, [
    currentPage,
    slides,
    currentSlide,
    selectedKvittering.data.id,
    hasFetchedKvittering,
  ])

  useEffect(() => {
    if (swipeTo === swipeToEnum.RIGHT) {
      goToPrevious()
    }

    if (swipeTo === swipeToEnum.LEFT) {
      onGoToNext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSwipe])

  useEffect(() => {
    window.scrollTo(0, 0)
    // Update url with currentSlide to stay there on refresh without rerender
    replaceQuery(`currentSlide=${currentSlide}`)
  }, [currentSlide, replaceQuery])

  useEffect(() => window.scrollTo(0, 0), [currentPage])

  useEffect(() => {
    if (formStatus === CHECKING && !loading) {
      const onSuccess = () => {
        setFormStatus(OK)
        goToSlide(5)
      }
      const onFail = (errorText: string) => {
        dispatch(dangerToast(errorText))
        setFormStatus(ERROR)
      }

      setIsValidationDirty(true)

      const kvittering = kvitteringQueryHelpers.getKvitteringCache(
        kvitteringId
      ) as IKvitteringData
      if (kvittering) {
        checkValidation(kvittering, onSuccess, onFail)
        return
      }
    }
  }, [loading, formStatus, dispatch, goToSlide, kvitteringId])

  useEffect(() => {
    if (
      !selectedKvittering.data.sakNumberValue ||
      (selectedKvittering.data.sakNumberValue &&
        selectedKvittering.data.sakNumberValue?.length < 1)
    ) {
      setBlockContinueFromZero(false)
    }
  }, [selectedKvittering.data.sakNumberValue])

  useEffect(() => {
    setHasFetchedKvittering(true)
    if (initialCurrentSlide > 0 && initialCurrentSlide < 5) {
      goToSlide(initialCurrentSlide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      hasFetchedKvittering &&
      !!selectedKvittering.data.id &&
      selectedKvittering.data.noarksakAar &&
      !isOffline
    ) {
      dispatch(
        getSak(
          [
            selectedKvittering.data.noarksakAar,
            selectedKvittering.data.noarksakSekvensnummer,
          ].join('/'),
          false
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKvittering.data.id, hasFetchedKvittering, isOffline])

  useEffect(() => {
    if (selectedKvittering.data.status === kvitteringStatusEnum.FORBEREDT) {
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          status: kvitteringStatusEnum.PAABEGYNT,
        })
      )
    }
  }, [selectedKvittering, dispatch])

  if (!selectedKvittering.data.id && selectedKvitteringStatus === ERROR) {
    return (
      <Row minHeight="calc(100vh - 300px)" center>
        <ErrorBox
          errorText="Kunne ikke hente innhold.."
          errorActionText="Prøv igjen.."
          errorAction={() =>
            dispatch(
              kvitteringActions.setCurrentKvitteringFromId(kvitteringId, [
                'tilsynsobjekter',
              ])
            )
          }
        />
      </Row>
    )
  }

  if (
    hasFetchedKvittering &&
    currentSlide !== 5 &&
    (isFerdigstiltStatus(selectedKvittering.data.status) ||
      isArkivertStatus(selectedKvittering.data.status))
  ) {
    const errorText = isArkivertStatus(selectedKvittering.data.status)
      ? 'Denne tilsynskvitteringen er allerede arkivert'
      : 'Denne tilsynskvitteringen er allerede ferdigstilt'
    return (
      <Row minHeight="calc(100vh - 300px)" center>
        <ErrorBox
          errorText={errorText}
          errorActionText="Gå tilbake til forsiden"
          errorAction={() => navigate('/')}
        />
      </Row>
    )
  }

  if (view === 'fullversjonImage') {
    return <FullImagePage onTilbake={onBack} />
  }

  if (view === 'tidligereKontrollpunkter') {
    return (
      <TidligereKontrollpunkter
        tilsynsobjekter={selectedKvittering.data.tilsynsobjekter}
        kvitteringId={selectedKvittering.data.id ?? ''}
        onCancel={onBack}
      />
    )
  }

  return (
    <Grid id="start-tilsyn">
      <LoadingModal
        isOpen={showLoadingModal}
        onCancel={() => setFormStatus(ERROR)}
      />

      {renderKvitteringPage()}

      {selectedKvittering.data.id &&
        selectedKvittering?.data.tilsynsobjekter && (
          <KvitteringNavigator
            slides={slides}
            currentSlide={currentSlide}
            currentPage={currentPage}
            toPreviousSlide={goToPrevious}
            toNextSlide={onGoToNext}
            toSlide={onGoToSlide}
            toPage={goToPage}
          />
        )}
    </Grid>
  )
}
