import { RecordKeys } from '../../common/typescript-utils'
import { IDeltaker } from '../../features/deltakere/types'
import { kvitteringActions } from './actions'
import { CommonReduxTypes } from '@mattilsynet/mt-common'
import { ITilsynsobjekt } from './tilsynsobjekt/types'
import { IVeiledning } from './veiledning/types'
import { IKontrollpunkt } from '../../features/kontrollpunkter/types'

export interface ITilsynstyper {
  loading: boolean
  loaded: boolean
  data: ITilsynstype[]
  error: string
}
export interface IKontrollpunktGruppe {
  beskrivelse: string
  tilsynskvitteringId: string
  tilsynsobjekter: {
    tilsynsobjektId: string
    id?: string
    aktivitetsId?: string
  }[]
  isCreating?: boolean
  isSavedLocally?: boolean
  id: string
  _links: {
    self: {
      href: string
    }
  }
  synced: boolean
  localData?: {
    offlineId: string
  }
}
export interface IKvitteringState {
  loading: boolean
  kvitteringer: IKvitteringData[]
  error: string
  selectedKvittering: {
    status: IKvitteringSavingStatus
    data: IKvitteringData
  }
  selectedTilsynsobjekt: {
    status: 'SAVING' | 'OK' | 'ERROR' | 'NONE'
    data: ITilsynsobjekt
  }
  latestKontrollpunktId: string
  tempKontrollpunkter: IKontrollpunkt[]
  tilsynstyper: ITilsynstyper
  hentedeKvitteringer: IHentedeKvitteringerState
}

export interface IKvitteringData {
  id?: string
  bildeIds: string[]
  tilsynsdato: string
  tilsynsdatoSlutt?: string
  noarksak: string
  journalpostId: string
  userId: string
  forhold: string
  observasjoner: string[]
  veiledninger: IVeiledning[]
  untattOffentlighet: boolean
  faktaarkUtlevert?: boolean
  mobilForUndersoekelse?: string
  beliggenhetsadresse?: IVirksomhetAdresse
  postadresse?: IVirksomhetAdresse
  adresse?: IPrivatPersonAdresse

  hjemmelForUnntattOffentlighet: string
  registrertInnholdGjennomgaat: boolean
  ferdigstilt: boolean
  hoveddokumentId?: ITilsynskvitteringRemote.TilsynskvitteringDTO['hoveddokumentId']
  status?: ITilsynskvitteringRemote.TilsynskvitteringDTO['status']
  opprettet: string
  oppdatert: string
  virksomhetsNavn?: string
  virksomhetsOrgNr?: string
  tilsynsobjekter: ITilsynsobjekt[]
  noarksakSekvensnummer?: string
  noarksakAar?: string
  sakNumberValue?: string
  _links?: any
  /** @deprecated */
  'mattilsynet-deltagere': IDeltaker[]
  /** @deprecated */
  'virksomhet-deltagere': IDeltaker[]
  /** @deprecated */
  'andre-deltagere': IDeltaker[]
  mattilsynetDeltagere?: IDeltaker[]
  virksomhetsDeltagere?: IDeltaker[]
  andreDeltagere?: IDeltaker[]
  kontrollpunktGrupper: IKontrollpunktGruppe[]
  bilderGjennomgaatt?: boolean
  bilderGjennomgaattBegrunnelse: string
  registrertInnholdGjennomgaatt?: boolean
  registrertInnholdGjennomgaattBegrunnelse: string
  ingenTilStede?: boolean
  ingenTilStedeBegrunnelse?: string
  mattilsynetSkjermet?: boolean
  mattilsynetSkjermetBegrunnelse?: string
  unntattOffentlighet?: boolean
  personSkjermet?: boolean
  typeTilsyn: string
  typeTilsynBeskrivelse?: string
  orgFormKode?: string
  virksomhetNavnSkalTagges?: boolean
  fordeltAv?: string
  generellInformasjon?: string
  fordelingInfo?: IFordelingInformasjon

  overordnetOrgNr?: string
  synced: boolean
}

export interface IIdentitet {
  identitetstype: string
  verdi: string
}

export interface ITilsynstype {
  typeTilsynKey: string
  beskrivelse: string
}

export const SAVING = 'SAVING'
export const OK = 'OK'
export const ERROR = 'ERROR'
export const NONE = 'NONE'
export const ERROR_FETCHING_SAMMENSTILLING = 'ERROR_FETCHING_SAMMENSTILLING'
export const ADRESSE_ERROR = 'ADRESSE_ERROR'
export const ANNET = 'Annet'

export type IKvitteringSavingStatus =
  | 'SAVING'
  | 'OK'
  | 'ERROR'
  | 'NONE'
  | 'ERROR_FETCHING_SAMMENSTILLING'
  | 'SAVING_VIRKSOMHETS_DELTAGER'

export const kvitteringStatusEnum: RecordKeys<
  Required<IKvitteringData>['status']
> = {
  ARKIVERING_PAAGAAR: 'ARKIVERING_PAAGAAR',
  ARKIVERING_PDF_FEILET: 'ARKIVERING_PDF_FEILET',
  ARKIVERING_VEDLEGG_FEILET: 'ARKIVERING_VEDLEGG_FEILET',
  ARKIVERT: 'ARKIVERT',
  FERDIGSTILLING_PAAGAAR: 'FERDIGSTILLING_PAAGAAR',
  FERDIGSTILLING_PDF_FEILET: 'FERDIGSTILLING_PDF_FEILET',
  FERDIGSTILLING_VEDLEGG_FEILET: 'FERDIGSTILLING_VEDLEGG_FEILET',
  FERDIGSTILT: 'FERDIGSTILT',
  FORBEREDT: 'FORBEREDT',
  PAABEGYNT: 'PAABEGYNT',
}

export interface IIdentitet {
  identitetstype: string
  verdi: string
}

export interface IVirksomhetAdresse {
  adresse: string[]
  postnummer: string
  poststed: string
}

export interface IPrivatPersonAdresse {
  gateadresse: string
  postNr: string
  poststed: string
}

export interface IAktivitet {
  value: string
  label: string
}

export interface IMidlertidigTilsynsobjektVirksomhet {
  navn: string
  nummer: string
}

export type IKvitteringActions = CommonReduxTypes.IGetActionTypesFromObject<
  typeof kvitteringActions
>

export interface IHentetKvitteringVirksomhet {
  adresse: IPrivatPersonAdresse
  id: string
  navn: string
  orgNr: string
  organisasjonsform: string
}

export interface IHentetKvittering {
  id: string
  status: ITilsynskvitteringRemote.TilsynskvitteringDTO['status']
  tilsynsdato: string
  tilsynsdatoSlutt?: string
  typeTilsyn: string
  typeTilsynBeskrivelse: string
  userId: string
  virksomhet: IHentetKvitteringVirksomhet
  harBilder: boolean
}

export interface IHentedeKvitteringerState {
  kvitteringer: IHentetKvittering[]
  loading: boolean
  loaded: boolean
  error: string
}

export interface IFordelingInformasjon {
  begrunnelse: string
  fordelingstype: 'HENTET' | 'FORDELT'
  forrigeEier: string
  forrigeEierNavn?: string
  id: number
  tidspunkt: string
  tilsynskvitteringId: number
}
